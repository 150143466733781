import { theme } from '@care/material-ui-theme';
import { useMediaQuery as useMuiMediaQuery } from '@mui/material';
import { HomePayVisitorHeaderLink } from '@care/navigation/dist/components/types';
import { useEffect, useState } from 'react';
import { PublicRuntimeConfig } from '@/interfaces';
import { ROUTING_PROPERTIES } from '@/constants';

export const useMobileQuery = () => useMuiMediaQuery(theme.breakpoints.down('md'));

export const useMediaSameAndDown = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') =>
  useMuiMediaQuery(theme.breakpoints.down(breakpoint));

export const useMediaSameAndUp = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') =>
  useMuiMediaQuery(theme.breakpoints.up(breakpoint));

export const useGetHeaderLeftSideLinks = (
  isMobile: boolean,
  publicRuntimeConfig: PublicRuntimeConfig,
  leftLinks?: HomePayVisitorHeaderLink[]
): HomePayVisitorHeaderLink[] => {
  const { HOMEPAY_URL, RESOURCE_CENTER_URL, FOR_PARTNERS_URL } = publicRuntimeConfig;
  const [leftSideLinks, setLeftSideLinks] = useState<HomePayVisitorHeaderLink[]>([]);

  useEffect(() => {
    setLeftSideLinks(
      leftLinks ?? [
        {
          text: 'Home',
          href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.mainVHP.relativeURL}`,
          type: 'link',
          isVisible: isMobile,
        },
        {
          text: 'Service & Pricing',
          href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.productAndPricing.relativeURL}`,
          type: 'link',
        },
        {
          text: 'Resource Center',
          href: RESOURCE_CENTER_URL,
          type: 'link',
        },
        {
          text: 'For Caregivers',
          href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.caregiver.relativeURL}`,
          type: 'link',
        },
        {
          text: 'For Partners',
          href: FOR_PARTNERS_URL,
          type: 'link',
        },
        {
          text: 'ProPortal',
          href: `${HOMEPAY_URL}${ROUTING_PROPERTIES.proPortal.relativeURL}`,
          type: 'link',
        } as HomePayVisitorHeaderLink,
      ]
    );
  }, [leftLinks, isMobile, HOMEPAY_URL, RESOURCE_CENTER_URL, FOR_PARTNERS_URL]);

  return leftSideLinks;
};
