import React, { ComponentType, FC, useEffect } from 'react';
import { Box, Container, ContainerProps, useMediaQuery } from '@mui/material';
import Head from 'next/head';
import getConfig from 'next/config';
import { theme } from '@care/material-ui-theme';
import { NextConfig } from '@/interfaces';
import { AmplitudeEventName, logAnalyticsEvent, PageProperties } from '@/utils/AnalyticsHelper';
import { CLIENT_FEATURE_FLAGS } from '@/constants';
import Header from './Header';
import Footer from './Footer';
import { useFeatureFlags } from '../contexts/FeatureFlagsContext';

export interface Meta {
  description: string;
  keywords: string;
  relativeCanonical?: string;
  title: string;
}

interface Props extends ContainerProps {
  FooterComponent?: ComponentType<{}>;
  HeaderComponent?: ComponentType<{}>;
  hasContainer?: boolean;
  hasFooterInContainer?: boolean;
  banner?: React.ReactNode;
  isMobile?: boolean;
  meta: Meta;
  pageProperties: PageProperties;
  skipScreenViewed?: boolean;
}

const { publicRuntimeConfig } = getConfig() as NextConfig;
const { CORE_BASE_URL, HOMEPAY_URL } = publicRuntimeConfig;

function getMeta(meta: Meta, favIconUrl: string) {
  const canonical =
    typeof meta.relativeCanonical === 'undefined'
      ? meta.relativeCanonical
      : `${HOMEPAY_URL}${meta.relativeCanonical}`;

  return (
    <>
      <title>{meta.title}</title>
      {canonical && <link href={canonical} rel="canonical" />}
      <link href={canonical} hrefLang="en-US" rel="alternate" />
      <link href={favIconUrl} rel="shortcut icon" />
      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      <link href={`${CORE_BASE_URL}/img/care-no-tag.jpg`} rel="image_src" />

      <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="IE=edge,chrome=1" httpEquiv="X-UA-Compatible" />
      <meta content="zz15lR180u9eCqTfur/9l+bMP75AampYtDEm6OnbkWY=" name="verify-v1" />
      <meta content={`${CORE_BASE_URL}/img/care-no-tag.jpg`} name="og:image" />
      <meta content="6030284861" name="fb:page_id" />
      <meta content={meta.description} name="description" />
      <meta content={meta.keywords} name="keywords" />
    </>
  );
}

const Layout: FC<Props> = ({
  FooterComponent,
  HeaderComponent,
  children,
  hasContainer = true,
  hasFooterInContainer = false,
  banner,
  isMobile,
  meta,
  pageProperties,
  skipScreenViewed = false,
  ...containerProps
}) => {
  const isMobileDefault = useMediaQuery('(max-width: 1155px)');
  const isMobileActual = isMobile ?? isMobileDefault;
  const ActualFooter = FooterComponent ?? Footer;
  const ActualHeader = HeaderComponent ?? Header;
  const { flags } = useFeatureFlags();
  const favIconUrl =
    flags[CLIENT_FEATURE_FLAGS.TTP_FAVICON_URL]?.value ?? `${CORE_BASE_URL}/img/crcm-favicon.ico`;

  useEffect(() => {
    if (!skipScreenViewed) {
      logAnalyticsEvent({
        name: AmplitudeEventName.screenViewed,
        pageProperties,
      });
    }
  }, [skipScreenViewed, pageProperties]);

  return (
    <>
      <Head>{getMeta(meta, favIconUrl)}</Head>
      <Box position="relative" zIndex={999}>
        <ActualHeader isMobile={isMobileActual} pageProperties={pageProperties} />
      </Box>
      {hasContainer ? (
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingTop: theme.spacing(13 + 0.2),
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: theme.spacing(7 + 0.2),
            },
          }}>
          {banner ?? null}
          <Container maxWidth="xl" {...containerProps}>
            {children}
          </Container>
        </Box>
      ) : (
        <Box
          pt={7}
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingTop: theme.spacing(13 + 0.2),
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: theme.spacing(7 + 0.2),
            },
          }}>
          {banner ?? null}
          {children}
        </Box>
      )}
      {hasFooterInContainer ? (
        <Container maxWidth="xl" {...containerProps}>
          <ActualFooter isMobile={isMobile} />
        </Container>
      ) : (
        <ActualFooter isMobile={isMobile} />
      )}
    </>
  );
};
export default Layout;
