import { FC, ReactNode } from 'react';
import getConfig from 'next/config';
import { HomePayVisitorFooter } from '@care/navigation';
import { HP_EMAIL, PHONE_LABEL, PHONE_LINK, ROUTING_PROPERTIES } from '@/constants';
import { NextConfig } from '@/interfaces';

const { publicRuntimeConfig } = getConfig() as NextConfig;

const {
  ABOUTUS_URL,
  HOMEPAY_URL,
  HOUSEHOLD_URL,
  NPR_URL,
  NTG_URL,
  RESOURCE_CENTER_CHILDCARE_URL,
  FOR_PARTNERS_URL,
  PRIVACY_POLICY_URL,
  SITEMAP_URL,
  SNC_URL,
  STATETAX_URL,
  TOS_URL,
} = publicRuntimeConfig;

type FooterSection = string | string[] | ReactNode | ReactNode[];

export interface FooterProps {
  featured?: FooterSection;
  isMobile?: boolean;
}

const Footer: FC<FooterProps> = ({ featured, isMobile = false }) => {
  return (
    <HomePayVisitorFooter
      isMobile={isMobile}
      featured={featured}
      footNotes={[
        <p>
          **Happiness Guarantee: We proudly stand behind the quality of our service. If you are
          unhappy with our service for any reason within the first 6 months after we activate your
          account, call our dedicated team of consultants at 1-888-273-3356 within that 6-month time
          period. We will then reimburse all HomePay fees paid by you prior to your call. A
          reimbursement may only be requested once under this guarantee, and cannot be used again if
          you reenroll. Reimbursement does not include fees for non-sufficient funds, credit card
          processing fees and payroll reversal fees. This Happiness Guarantee is valid for clients
          who pay HomePay directly for quarterly or monthly service fees. It is not valid for
          corporate clients including Care@Work and senior care agency clients.
        </p>,
        <p>
          ***Service Accuracy Guarantee: We guarantee (i) that your tax returns will be filed
          accurately and on time, and (ii) that your tax remittances will be paid on schedule if, in
          each of the foregoing cases, we receive your complete and accurate information by the end
          of the calendar quarter. If you incur monetary penalties from tax regulators due to our
          fault, and we are not successful in getting those amounts waived, we will at our
          discretion either pay those penalties on your behalf, or reimburse you for those amounts,
          in each case, excluding any other liability or the underlying tax obligation itself. We
          will not be at fault if we cannot collect the necessary amounts from your bank account or
          credit card on the day we request it. Please reference the Service Accuracy Guarantee by
          telephone at 888-273-3356 with respect to an error made during the period such
          recipient(s) were HomePay customers.
        </p>,
      ]}
      linkColumns={[
        {
          groupTitle: 'About Homepay',
          links: [
            {
              label: 'About us',
              address: ABOUTUS_URL,
            },
            {
              label: 'Site map',
              address: SITEMAP_URL,
            },
            {
              label: 'Terms of use',
              address: TOS_URL,
            },
            {
              label: 'Privacy policy',
              address: PRIVACY_POLICY_URL,
            },
          ],
        },
        {
          groupTitle: 'Tax & payroll service',
          links: [
            {
              label: 'Service & pricing',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.productAndPricing.relativeURL}`,
            },
            {
              label: 'Nanny',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.productAndPricing.relativeURL}`,
            },
            {
              label: 'Senior care',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.seniorCare.relativeURL}`,
            },
            {
              label: 'Household workers',
              address: HOUSEHOLD_URL,
            },
            {
              label: 'For partners',
              address: FOR_PARTNERS_URL,
            },
            {
              label: 'For caregivers',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.caregiver.relativeURL}`,
            },
            {
              label: 'ProPortal',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.proPortal.relativeURL}`,
            },
          ],
        },
        {
          groupTitle: 'Useful resources',
          links: [
            {
              label: 'Resource center',
              address: RESOURCE_CENTER_CHILDCARE_URL,
            },
            {
              label: 'Nanny tax guide',
              address: NTG_URL,
            },
            {
              label: 'Sample nanny contract',
              address: SNC_URL,
            },
            {
              label: 'Nanny pay rates',
              address: NPR_URL,
            },
            {
              label: 'State tax & payroll laws',
              address: STATETAX_URL,
            },
            {
              label: 'Nanny tax calculator',
              address: `${HOMEPAY_URL}${ROUTING_PROPERTIES.nannyTaxCalculator.relativeURL}`,
            },
          ],
        },
        {
          groupTitle: 'Contact us',
          links: [
            {
              label: `Call toll-free ${PHONE_LABEL}`,
              address: PHONE_LINK,
            },
            {
              label: 'Mon - Thurs: 8am - 6pm CT',
              address: '#',
            },
            {
              label: 'Fri: 8am - 5pm CT',
              address: '#',
            },
            {
              label: `Email: ${HP_EMAIL}`,
              address: `mailto:${HP_EMAIL}`,
            },
          ],
        },
      ]}
    />
  );
};

export default Footer;
